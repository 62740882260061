import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Screen from './Screen';
import AccountPanel from 'packages/common/base/components/AccountPanel';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setAvatarImageUrl, setAvatarObjectUrl, track } from '../../state/app';
import useAuth from '../../hooks/useAuth';
import { TrackingContext } from '../../typescript/typings';

interface Account {
    className?: string;
    visible: boolean;
    onChangeName: (name: string) => void;
    onSignup: (context: TrackingContext) => void;
    onLogin: (context: TrackingContext) => void;
    onLogout: () => void;
    onToggleNotifications: () => void;
    onRemoveAvatar: () => void;
    onClose: () => void;
}

const Account: React.FunctionComponent<Account> = (props) => {
    const {
        className,
        visible,
        onChangeName,
        onSignup,
        onLogin,
        onLogout,
        onToggleNotifications,
        onRemoveAvatar,
        onClose,
    } = props;

    const { user, loggedIn } = useAuth();
    const chatNotificationsEnabled = useAppSelector((state) => state.messages.notificationsEnabled);
    const mobile = useAppSelector((state) => state.app.mobile);
    const dispatch = useAppDispatch();

    return (
        <Screen visible={visible} mobile={mobile} header="Account Settings">
            <AccountStyled className={className}>
                <AccountPanelStyled
                    mobile={mobile}
                    name={user.name || 'Guest'}
                    userID={user.id}
                    avatarUrl={user.avatar?.url}
                    email={user.email}
                    chatNotificationsEnabled={chatNotificationsEnabled}
                    onChangeName={onChangeName}
                    onLogout={onLogout}
                    onAvatarFileChange={(objectUrl: string) => {
                        dispatch(track({ event: 'Select Avatar File' }));
                        dispatch(setAvatarObjectUrl(objectUrl));
                    }}
                    onEditAvatar={() => {
                        dispatch(track({ event: 'Click Edit Avatar' }));
                        dispatch(setAvatarImageUrl(user.avatar?.url));
                    }}
                    onRemoveAvatar={onRemoveAvatar}
                    onClickNameInput={() => {
                        dispatch(track({ event: 'Click Edit Username' }));

                        if (!loggedIn) {
                            onSignup(TrackingContext.AccountDropdown);
                        }
                    }}
                    onSignup={() => {
                        onSignup(TrackingContext.AccountDropdown);
                    }}
                    onLogin={() => {
                        onLogin(TrackingContext.AccountDropdown);
                    }}
                    onToggleChatNotifications={onToggleNotifications}
                />
            </AccountStyled>
        </Screen>
    );
};

const AccountPanelStyled = styled(AccountPanel)`
    align-self: stretch;
    flex: 1;
`;

const AccountStyled = styled.div`
    align-self: stretch;
    flex: 1;
    display: flex;
`;

export default Account;
