import React from 'react';
import styled from 'styled-components';
import Screen from './Screen';
import { useAppSelector } from '../../state/hooks';
import useAuth from '../../hooks/useAuth';
import { FEEDBACK_DROPDOWN_CONFIG } from '../../constants/room';
import FeedbackForm from 'packages/common/base/components/FeedbackForm';

interface Account {
    visible: boolean;
    onSubmitFeedback: (feedback: any) => void;
}

const Account: React.FunctionComponent<Account> = (props) => {
    const { visible, onSubmitFeedback } = props;

    const { user } = useAuth();
    const room = useAppSelector((state) => state.room);
    const mobile = useAppSelector((state) => state.app.mobile);

    return (
        <Screen visible={visible} mobile={mobile} header="Feedback">
            <FeedbackFormStyled
                visible
                app="web"
                mobile={mobile}
                userID={user.id}
                participantID={room.participantID}
                userName={user.name}
                roomUrlName={room.urlName}
                steps={FEEDBACK_DROPDOWN_CONFIG}
                onSubmit={onSubmitFeedback}
            />
        </Screen>
    );
};

const FeedbackFormStyled = styled(FeedbackForm)`
    align-self: stretch;
`;

export default Account;
