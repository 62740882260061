import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

interface Screen {
    className?: string;
    mobile: boolean;
    visible: boolean;
    header: string | React.ReactNode;
}

const Screen: React.FunctionComponent<Screen> = (props) => {
    const { className, mobile, visible, header, children } = props;

    return (
        <ScreenStyled visible={visible} mobile={mobile}>
            <HeaderStyled>
                {typeof header === 'string' ? (
                    <HeaderTextStyled>{header}</HeaderTextStyled>
                ) : (
                    header
                )}
            </HeaderStyled>
            <MainStyled>
                <ContentsStyled className={className}>{children}</ContentsStyled>
            </MainStyled>
        </ScreenStyled>
    );
};

const MainStyled = styled.div`
    flex: 1;
    overflow-y: auto;
`;

const ContentsStyled = styled.div`
    flex: 1;
    padding: ${rem(16)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    min-height: 100%;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: ${rem(20)};
    }
`;

const HeaderTextStyled = styled.h3`
    ${({ theme }) => theme.typography.Heading16}
`;

const HeaderStyled = styled.div`
    padding: 0 ${rem(64)};
    height: ${rem(64)};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`;

const ScreenStyled = styled.div<{ visible: boolean; mobile: boolean }>`
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;

    ${({ mobile }) =>
        mobile &&
        css`
            overflow: visible;
        `}
`;

export default Screen;
