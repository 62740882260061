import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { TVAuthSubscription } from 'packages/common/api-client/types';
import Column from 'packages/common/base/styles/components/Column';
import Centered from 'packages/common/base/styles/components/Centered';
import FilledButton from 'packages/common/base/components/FilledButton';
import Row from 'packages/common/base/styles/components/Row';
import { platforms } from '../constants/tvAuth';
import { platformsToNames } from 'packages/common/tv-auth/platforms';
import CheckIcon from 'packages/common/assets/svg/check.svg';

interface ConnectedSubscription {
    className?: string;
    subscription: TVAuthSubscription;
    onDisconnect: () => void;
}

const ConnectedSubscription: React.FunctionComponent<ConnectedSubscription> = (props) => {
    const { className, subscription, onDisconnect } = props;

    const platform = platforms.find((platform) => platform.platform === subscription.platform);

    if (!platform) {
        return null;
    }

    return (
        <ConnectedSubscriptionStyled className={className} alignItems="stretch">
            <SubscriptionIconContainerStyled>
                <SubscriptionLogoStyled
                    src={platform.icon}
                    alt={`${platformsToNames[subscription.platform]} logo`}
                />
                <CheckStyled>
                    <CheckIcon />
                </CheckStyled>
            </SubscriptionIconContainerStyled>
            <SubscriptionDetailsContainerStyled>
                <SubscriptionDetailsStyled>
                    <SubscriptionNameStyled alignItems="center">
                        {platformsToNames[subscription.platform]}
                    </SubscriptionNameStyled>
                    <SubscriptionDateStyled>{subscription.username}</SubscriptionDateStyled>
                </SubscriptionDetailsStyled>

                <ConnectStatusStyled>
                    <ConnectedStyled size="sm" format="success">
                        Connected
                    </ConnectedStyled>
                    <DisconnectStyled size="sm" format="negative" onClick={onDisconnect}>
                        Disconnect
                    </DisconnectStyled>
                </ConnectStatusStyled>
            </SubscriptionDetailsContainerStyled>
        </ConnectedSubscriptionStyled>
    );
};

const DisconnectStyled = styled(FilledButton)`
    width: ${rem(96)};
    display: none;
`;

const ConnectedStyled = styled(FilledButton)`
    width: ${rem(96)};
`;

const SubscriptionLogoStyled = styled.img`
    height: ${rem(80)};
    width: ${rem(142)};
`;

const SubscriptionIconContainerStyled = styled(Centered)`
    background-color: ${({ theme }) => theme.palette.DarkGrey2};
    position: relative;
`;

const SubscriptionDetailsContainerStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${rem(16)};
    background-color: ${({ theme }) => theme.palette.MedGrey4};
`;

const SubscriptionDateStyled = styled.div`
    ${({ theme }) => theme.typography.Paragraph13}
    ${({ theme }) => theme.typography.EllipsisOverflow}
`;

const SubscriptionNameStyled = styled(Row)`
    ${({ theme }) => theme.typography.Heading13Standard}
    ${({ theme }) => theme.typography.EllipsisOverflow}
    margin-bottom: ${rem(2)};
`;

const CheckStyled = styled(Centered)`
    height: ${rem(20)};
    width: ${rem(20)};
    background-color: ${({ theme }) => theme.palette.GreenTransparent1};
    border-radius: 50%;
    position: absolute;
    top: ${rem(12)};
    right: ${rem(12)};

    svg {
        height: ${rem(14)};
        width: ${rem(14)};
        color: ${({ theme }) => theme.palette.Green};
    }
`;

const SubscriptionDetailsStyled = styled(Column)`
    overflow: hidden;
    flex-shrink: 1;
`;

const ConnectStatusStyled = styled.div`
    flex-shrink: 0;

    @media (hover: hover) {
        &:hover {
            ${ConnectedStyled} {
                display: none;
            }

            ${DisconnectStyled} {
                display: flex;
            }
        }
    }

    @media (hover: none) {
        ${ConnectedStyled} {
            display: none;
        }

        ${DisconnectStyled} {
            display: flex;
        }
    }
`;

const ConnectedSubscriptionStyled = styled(Column)`
    width: 100%;
    box-sizing: border-box;
    border-radius: ${rem(4)};
    overflow: hidden;
`;

export default ConnectedSubscription;
