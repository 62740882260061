import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Screen from './Screen';
import { AccountPanelScreen } from './AccountPanel';
import { useAppSelector } from '../../state/hooks';
import { PermissionType } from 'packages/common/api-client/types';
import Column from 'packages/common/base/styles/components/Column';

interface Menu {
    visible: boolean;
    onClickItem: (screen: AccountPanelScreen) => void;
    onClickDiscord: () => void;
}

const Menu: React.FC<Menu> = (props) => {
    const { visible, onClickItem, onClickDiscord } = props;

    const roomID = useAppSelector((state) => state.room.id);
    const mobile = useAppSelector((state) => state.app.mobile);

    return (
        <Screen mobile={mobile} header={<Header />} visible={visible}>
            <MenuItemStyled
                onClick={() => {
                    onClickItem(AccountPanelScreen.Account);
                }}
            >
                Account
            </MenuItemStyled>
            <MenuItemStyled
                onClick={() => {
                    onClickItem(AccountPanelScreen.Subscriptions);
                }}
            >
                TV Subscriptions
            </MenuItemStyled>
            {!!roomID && (
                <MenuItemStyled
                    onClick={() => {
                        onClickItem(AccountPanelScreen.Feedback);
                    }}
                >
                    Feedback
                </MenuItemStyled>
            )}
            <FooterStyled alignItems="center">
                <DiscordLinkStyled
                    href="https://discord.gg/vBkzkH2ptQ"
                    target="_blank"
                    onClick={onClickDiscord}
                >
                    Join our Discord
                </DiscordLinkStyled>
                <CopyrightStyled>{`© ${new Date().getFullYear()} Playback Inc.`}</CopyrightStyled>
            </FooterStyled>
        </Screen>
    );
};

const Header: React.FC<{}> = () => {
    const name = useAppSelector((state) => state.user.name);
    const userID = useAppSelector((state) => state.user.id);
    const room = useAppSelector((state) => state.room.instance);

    const getMemberType = () => {
        let type = 'Member';

        if (!room?.id) {
            return type;
        }

        if (userID === room.userID) {
            type = 'Creator';
        } else if (
            room.permissions.some(
                (p) =>
                    p.userID === userID && p.enabled && p.permission === PermissionType.StageInvite
            )
        ) {
            type = 'Mod';
        }

        return type;
    };

    return (
        <HeaderStyled>
            <MemberNameStyled>{name}</MemberNameStyled>
            <MemberTypeStyled>{getMemberType()}</MemberTypeStyled>
        </HeaderStyled>
    );
};

const DiscordLinkStyled = styled.a`
    ${({ theme }) => theme.typography.Heading16}
    margin-bottom: ${rem(8)};

    @media (hover: hover) {
        &:hover {
            text-decoration: underline;
            text-underline-offset: ${rem(4)};
        }
    }
`;

const CopyrightStyled = styled.p`
    ${({ theme }) => theme.typography.Paragraph16}
    text-align: center;
`;

const FooterStyled = styled(Column)`
    margin-top: auto;
    padding: 0;
    align-self: stretch;
`;

const MemberTypeStyled = styled.p`
    ${({ theme }) => theme.typography.Paragraph13}
`;

const MemberNameStyled = styled.h4`
    ${({ theme }) => theme.typography.Heading16}
`;

const HeaderStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MenuItemStyled = styled.button`
    ${({ theme }) => theme.typography.Heading24}
    align-self: stretch;
    text-align: left;

    & + & {
        margin-top: ${rem(16)};
    }

    @media (hover: hover) {
        &:hover {
            text-decoration: underline;
            text-decoration-thickness: ${rem(2)};
            text-underline-offset: ${rem(4)};
        }
    }
`;

const MenuStyled = styled.div``;

export default Menu;
